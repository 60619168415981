import graphql from 'graphql-anywhere'
import gql from 'graphql-tag'
import orderBy from 'lodash.orderby'
import typeMap from './TypeMap.js'
import CacheEmitter from './CacheEmitter.js'
import { resolverService } from './services/resolver-service.js'

import dashCopy from '../util/dashCopy/index.js'

let UID = 'id'

const isLeaf = (obj) => {
    for (let key in obj) {
        if (obj[key] instanceof Object) {
            return false
        }
    }
    return true
}
const getChildType = (obj) => {
    if (Array.isArray(obj)) {
        if (obj.length > 0) {
            return obj[0]['__typename']
        }
    }
    return typeMap.guessChildType(obj['__typename'])
}

const deepDiffMapper = function() {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function(obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1
          };
        }

        var diff = {};
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }

          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }

          diff[key] = this.map(obj1[key], value2);
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }

          diff[key] = this.map(undefined, obj2[key]);
        }

        return diff;

      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      }
    }
}()
class DelvDebug{
    constructor(cache){
        this.cache = cache
        this.snapshots = {}
        this.snapshotCounter = 1
        window.delvDebug = this
    }

    snapshotCache = (id) => {
        if(!id){
            id = this.snapshotCounter++
        }
        if(this.snapshots[id]){
            console.error(`Snapshot with id ${id} already exists`)
        }else{

            this.snapshots[id] = JSON.parse(JSON.stringify(this.cache.cache)) //hacky deep clone
        }
    }

    objectDiff = (obj1, obj2) => {
        return deepDiffMapper.map(obj1, obj2)
    }

    snapshotDiff = (id1, id2) => {
        if(!this.snapshots[id1]){
            console.error(`Snapshot with id ${id1} does not exist`)
            return
        }
        if(!this.snapshots[id2]){
            console.error(`Snapshot with id ${id2} does not exist`)
            return
        }
        return this.objectDiff(this.snapshots[id1], this.snapshots[id2])
    }
}

class Cache {
    constructor(resolverOverride = {} , keyConflicts = {}) {
        this.resolverOverride = resolverOverride
        this.cache = {}
        this.emitter = CacheEmitter
        this.keyConflict = new Map()
        Object.keys(keyConflicts).forEach((key)=>this.keyConflict.set(key, keyConflicts[key]))
        this.debug = new DelvDebug(this)
    }

    resolver = (fieldName, root, args, context, info) => resolverService(
        fieldName,
        root,
        args,
        context,
        info,
        {
            cache: this.cache,
            keyConflict: this.keyConflict,
            filterCacheByIds: this.filterCacheByIds,
            filterCache: this.filterCache,
        }    
    )

    checkFilter = (filter, value) => {
        let match = true
        for (let key in filter) {
            let filterValue = filter[key]
            if (key === 'equalTo') {
                match = match && filterValue === value
            } else if (key === 'greaterThanOrEqualTo') {
                match =
                    match &&
                        new Date(filterValue).getTime() <= new Date(value).getTime()
            } else if (key === 'notEqualTo') {
                // eslint-disable-next-line
                match = match && value != filterValue
            } else if (key === 'greaterThan') {
                match = match && filterValue < value
            } else if (key === 'lessThanOrEqualTo') {
                match =
                    match &&
                    new Date(filterValue).getTime() >= new Date(value).getTime()
            } else if (key === 'lessThan') {
                match = match && filterValue > value
            } else if (key.endsWith('Exist')) {
                if (value instanceof Array) {
                    match = match && value.length > 0
                } else {
                    match = match && !!value
                }
            } else if(key === 'in') {
                match = match && filterValue.includes(value)
            } else if(key === 'includes') {
                match = match && value?.includes(filterValue)
            } else if(key === 'includesInsensitive') {
                match = match && value?.toLowerCase().includes(filterValue.toLowerCase())
            }
        }
        return match
    }

    filterCacheByIds = (type, ids) => {
        const selected = ids.map((id) => this.cache[type][`${id}`])
        return selected
    }

    filterCache = (set, args, cacheContext) => {
        let returnVal = set
        const _this = this
        if (args.condition) {
            returnVal = dashCopy.pickBy(returnVal, (value, key) => {
                let match = true
                for (let k in args.condition) {
                    if (value[k] !== args.condition[k]) {
                        match = false
                    }
                }
                return match
            })
        }
        if (args.filter) {
            returnVal = dashCopy.pickBy(returnVal, (value, key) => {
                let match = true
                for (let k in args.filter) {
                    let returnValHasField = value[k]
                    let type
                    let filter = args.filter[k]
                    if (k.endsWith('Exist')) {
                        type = typeMap.get(k.replace('Exist', ''))
                        type = typeMap.guessChildType(type)
                        returnValHasField = value[type]
                        filter = {[k]: args.filter[k]}
                    }
                    if (returnValHasField !== undefined) {
                        if (!_this.checkFilter(filter, returnValHasField)) {
                            match = false
                        }
                    } else {
                        match = false
                        if (!value.totalCount && value.totalCount !== 0) {
                            console.warn(
                                `Key data ${k} not found, cannot complete filter`
                            )
                        }
                    }
                }
                return match
            })
        }
        if ('first' in args && 'offset' in args) {
            returnVal = dashCopy.pickBy(returnVal, (item) => {
                const queries = item.pagination?.[cacheContext.query.partialHash.toString()]

                const shouldDisplay = queries?.some((queryHash) => {
                    const query = item.pagination[queryHash]
                    const queryOffset = Number(query.offset)
                    const queryFirst = Number(query.first)
                    const contextOffset = Number(cacheContext.pagination.offset)
                    const contextFirst = Number(cacheContext.pagination.first)
                    const isValidQuery = (queryOffset + queryFirst) <= (contextOffset + contextFirst)
    
                    return Boolean(query && isValidQuery)
                })

                return shouldDisplay
            })
        }
        if (args.orderBy) {
            const OrderByMap = {
                ID_DESC: { field: 'id', direction: 'desc' },
                ID_ASC: { field: 'id', direction: 'asc' },
                CREATED_ON_DESC: { field: 'createdOn', direction: 'desc' },
                CREATED_ON_ASC: { field: 'createdOn', direction: 'asc' },
                UPDATED_ON_DESC: { field: 'updatedOn', direction: 'desc' },
                UPDATED_ON_ASC: { field: 'updatedOn', direction: 'asc' },
                OPENS_ON_DESC: { field: 'opensOn', direction: 'desc' },
                OPENS_ON_ASC: { field: 'opensOn', direction: 'asc' },
                TYPE_ASC: { field: 'type', direction: 'asc' },
                TYPE_DESC: { field: 'type', direction: 'desc' },

            }

            const sortCallBack = (fields, directions) => (arr) => orderBy(arr, fields, directions)

            if (Array.isArray(args.orderBy)) {
                const newOrderBy = args.orderBy.reduce((acc, order) => {
                    const orderOpts = OrderByMap[order] || {}

                    acc.fields.push(orderOpts.field)
                    acc.directions.push(orderOpts.direction)

                    return acc
                }, {
                    fields: [],
                    directions: [],
                })

                returnVal = {
                    data: returnVal,
                    sort: sortCallBack(newOrderBy.fields, newOrderBy.directions)
                }
            } else if (OrderByMap[args.orderBy]) {
                const newOrderBy = OrderByMap[args.orderBy]

                returnVal =  {
                    data: returnVal,
                    sort: sortCallBack([newOrderBy.field], [newOrderBy.direction])
                }
            } else {
                console.error(`Order by condition not supported, please add support ${args.orderBy}`)
            }
        }

        return returnVal
    }

    merge = (objectFromCache, objectFromNetwork) => {
        const customizer = (objValue, srcValue, key, object, source, stack) => {
            if (Array.isArray(objValue)) {
                return dashCopy.union(objValue, srcValue)
            }
        }

        const mergedObjects = dashCopy.mergeWith(objectFromCache, objectFromNetwork, customizer)

        return mergedObjects
    }

    formatObject = (object, isRoot, parentObject, context) => {
        if (object === null) {
            return false
        }
        // Recursively format objects in array
        if (object instanceof Array) {
            object.forEach((item) => {
                this.formatObject(item, null, parentObject, context)
            })
            // must return here
            return
        }
        // handles query and payload types
        if (
            object['__typename'].endsWith('Payload') ||
            object['__typename'] === 'query'
        ) {
            for (let key in object) {
                let value = object[key]
                if (key !== '__typename' && value instanceof Object) {
                    this.formatObject(value, null, null, context)
                }
            }
            return
        }

        // caches leafs
        if (isLeaf(object)) {
            if (isRoot) {
                this.cache[isRoot] = object[UID]
            }
            // create bidirectional link with parent
            let clone = dashCopy.cloneDeep(object)
            
            if (parentObject) {
                let temp = clone[parentObject.type]
                if (temp) {
                    if (Array.isArray(temp)) {
                        clone[parentObject.type] = [...temp, parentObject.uid]
                    } else {
                        clone[parentObject.type] = [temp, parentObject.uid]
                    }
                } else {
                    clone[parentObject.type] = parentObject.uid
                }
            }
            if (object.totalCount) {
                this.cache[typename][context.query.hash].totalCount = object.totalCount
                this.cache[typename][context.query.partialHash].totalCount = object.totalCount
            }
            this.updateCacheValue(clone, context)
            return object[UID]
        }

        // caches connections
        if (object['__typename'].endsWith('Connection')) {
            if (!this.cache[getChildType(object)]) {
                this.cache[getChildType(object)] = {}
            }
            if (parentObject) {
                parentObject['uid'] = parentObject['uid'][0]
            }
            // flattens nodes and edges
            if (object.nodes) {
                if (object.totalCount) {
					const typename = object.nodes[0]['__typename']
                    if (!this.cache[typename]) {
                        this.cache[typename] = {}   
                    }
                    if (!this.cache[typename][context.query.hash]) {
                        this.cache[typename][context.query.hash] = {}
                    }
                    if (!this.cache[typename][context.query.partialHash]) {
                        this.cache[typename][context.query.partialHash] = {}
                    }
                    this.cache[typename][context.query.hash].totalCount = object.totalCount
                    this.cache[typename][context.query.partialHash].totalCount = object.totalCount
                }
                return object.nodes.map((obj) => {
                    this.formatObject(obj, false, parentObject, context)
                    return obj[UID]
                })
            } else if (object.edges) {
                return object.edges.map((obj) => {
                    this.formatObject(obj.node, false, parentObject, context)
                    return obj.node[UID]
                })
            }
        }
        let clone = dashCopy.cloneDeep(object)

        if (parentObject) {
            let temp = clone[parentObject.type]
            if (temp) {
                if (Array.isArray(temp)) {
                    clone[parentObject.type] = [...temp, parentObject.uid]
                } else {
                    clone[parentObject.type] = [temp, parentObject.uid]
                }
            } else {
                clone[parentObject.type] = parentObject.uid
            }
        }
        let type = clone['__typename']
        for (let key in object) {
            if (key === '__typename') {
                continue
            }
            let value = object[key]
            if (typeMap.get(key) === 'JSON') {
                continue
            }
            if (value instanceof Object) {
                let conflict = this.keyConflict.get(key)
                if (conflict) {
                    clone[key] = this.formatObject(value, false, {
                        type: conflict,
                        uid: [clone[UID]],
                    }, context)
                } else {
                    clone[getChildType(value)] = this.formatObject(
                        value,
                        false,
                        {type: type, uid: [clone[UID]]},
                        context
                    )
                    delete clone[key]
                }
            }
        }
        this.updateCacheValue(clone, context)
        return clone[UID]
    }

    updateCacheValue = (obj, context) => {
        let typename = obj['__typename']
        if (!this.cache[typename]) {
            this.cache[typename] = {}
        }
        let cacheVal = this.cache[typename][obj[UID]]
        if (cacheVal) {
            if (!dashCopy.isEqual(cacheVal, obj)) {
                CacheEmitter.changeType(typename)
                this.cache[typename][obj[UID]] = this.merge(cacheVal, obj)
                if (context.pagination) {
                    this.cache[typename][obj[UID]].pagination = this.cache[typename][obj[UID]].pagination || {}
                    this.cache[typename][obj[UID]].pagination[context.query.hash] = {
                        first: context.pagination?.first,
                        offset: context.pagination?.offset,
                    }
                    this.cache[typename][obj[UID]].pagination[context.query.partialHash] = Boolean(this.cache[typename][obj[UID]].pagination[context.query.partialHash])
                        ? [...this.cache[typename][obj[UID]].pagination[context.query.partialHash], context.query.hash]
                        : [context.query.hash]
                }
            }
        } else {
            CacheEmitter.changeType(typename)
            this.cache[typename][obj[UID]] = obj
            if (context.pagination) {
                this.cache[typename][obj[UID]].pagination = this.cache[typename][obj[UID]].pagination || {}
                this.cache[typename][obj[UID]].pagination[context.query.hash] = {
                    first: context.pagination?.first,
                    offset: context.pagination?.offset,
                }
                
                this.cache[typename][obj[UID]].pagination[context.query.partialHash] = Boolean(this.cache[typename][obj[UID]].pagination[context.query.partialHash])
                    ? [...this.cache[typename][obj[UID]].pagination[context.query.partialHash], context.query.hash]
                    : [context.query.hash]
            }
        }
        if (obj.totalCount) {
            this.cache[typename][context.query.hash].totalCount = obj.totalCount
        }
    }

    removeId = ({
        id,
        removeId,
        type,
        removeFieldName
    }) => {
        if(this.cache[type][id][removeFieldName] instanceof Array){
            this.cache[type][id][removeFieldName] =
            this.cache[type][id][removeFieldName].filter((id)=>id!==removeId)
        }else{
            delete this.cache[type][id][removeFieldName]
        }
    }

    removeObject = (obj) => { //this is bad code
        const objType = obj['__typename']
        const objUID = obj[UID]
        CacheEmitter.changeType(objType)
        const cachedItem = this.cache[objType][objUID]
        delete this.cache[objType][objUID]
        for (let key in cachedItem) { //lets traverse for nodes referencing that node
            const value = cachedItem[key]
            let conflict = this.keyConflict.get(key)
            const referencedNodeType = conflict?this.typeMap.get(key):key
            if(this.cache[referencedNodeType]){ //if the node is in the base cache
                //this will collide  if we mess up naming, dont name db feilds the same as db tables.
                //case matters
                CacheEmitter.changeType(referencedNodeType)
                if(value instanceof Array){
                    value.forEach((id)=>{
                        this.removeId({
                            id,
                            removeId:cachedItem.id,
                            type:referencedNodeType,
                            removeFieldName:conflict || objType
                        })
                    })
                }else{
                    this.removeId({
                        id:value,
                        removeId:cachedItem.id,
                        type:referencedNodeType,
                        removeFieldName:conflict || objType
                    })
                }
            }
        }
    }

    remove = (queryResult) => {
        for (let key in queryResult) {
            if (key === '__typename') {
                continue
            }
            let value = queryResult[key]
            if (
                value['__typename'].startsWith('Delete') ||
                value['__typename'].startsWith('Remove')
            ) {
                for (let k in value) {
                    if (k === '__typename') {
                        continue
                    }
                    this.removeObject(value[k])
                }
            } else if (
                value['__typename'].startsWith('Create') ||
                value['__typename'].startsWith('Make')
            ) {
                for (let k in value) {
                    if (k === '__typename') {
                        continue
                    }
                    this.formatObject(value[k])
                }
            }else{
                for (let k in value) {
                    if (k === '__typename') {
                        continue
                    }
                    this.removeObject(value[k])
                }
            }
        }
        CacheEmitter.emitCacheUpdate()
    }

    cacheByType = (queryResult, context = {}) => {
        let result = dashCopy.cloneDeep(queryResult)
        for (let key in result) {
            if (key !== '__typename') {
                this.formatObject(result[key], key, null, context)
            }
        }
        CacheEmitter.emitCacheUpdate()
    }

    loadByType = (query, cacheContext) => {
        try {
            const loadedData = graphql(
                this.resolver,
                gql`
                    ${query}
                `,
                this.cache,
                cacheContext,
            )

            return loadedData
        } catch (error) {
            return {
                error: 'error loading query: ' + error.message,
            }
        }
    }

    cacheByQuery = (data, id) => {
        this.cache[id] = data
        if (id.startsWith('__')) {
            this.emitter.changeType(id)
            this.emitter.emitCacheUpdate()
        }
    }

    loadByQuery = (id) => {
        return this.cache[id]
    }

    clearCache = () => {
        this.cache = {}
    }

    add = (data, process, id, context) => {
        try {
            if (process instanceof Object) {
                process(this.cache, data, this.emitter, this)
            } else {
                switch (process) {
                    case 'default':
                    case 'infiniteScroll':
                        this.cacheByType(data, context)
                        break
                    case 'query':
                        this.cacheByQuery(data, id)
                        break
                    case 'update':
                        //cache.processUpdate
                        break
                    case 'delete':
                        this.remove(data)
                        break
                    case 'skip':
                        //do nothing
                        break
                    default:
                        break
                }
            }
        } catch (error) {
            console.error(error)
            this.debug.snapshotCache()
            this.debug.snapshotCache('error')
            console.error(
                `Error occured trying to cache response data: ${error.message}`
            )
        }
    }

    get = (query, process, id, cacheContext) => {
        try {
            switch (process) {
                case 'default':
                case 'infiniteScroll':
                    return this.loadByType(query, cacheContext)
                case 'query':
                    return this.loadByQuery(id)
                default:
                    return null
            }
        } catch (error) {
            console.error(
                `Error occured trying to load chace data: ${error.message}`
            )
        }
    }
}

export default Cache
