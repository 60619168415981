import * as React from 'react'

import useArgs from 'hooks/UseArgs.js'
import Query from '../Query.js'

const useQuery = (queryInput, {
    args,
    cacheProcess,
    format,
    networkPolicy,
} = {}) => {
    const [data, setData] = React.useState(undefined)
    const [error, setError] = React.useState(undefined)
    const [loading, setLoading] = React.useState(true)

    if (typeof queryInput !== 'function' && args) {
        // eslint-disable-next-line no-console
        console.error('Error: Delv useQuery must intake query as function to use args')
    }

    const query = typeof queryInput === 'function' ? queryInput(args) : queryInput

    React.useEffect(() => {
        console.log('rerender delv hook', args)
        if (!query) {
            setData(undefined)
            return
        }

        setLoading(true)
        setError(undefined)

        new Query({
            query,
            onResolve: (res) => {
                console.log('SETTING DATA', res)
                setData(format ? format(res) : res)
                setLoading(false)
            },
            onError: (err) => {
                setError(err?.[0].message)
                setLoading(false)
            },
            cacheProcess: cacheProcess || 'default',
            networkPolicy: networkPolicy || 'network-once',
        }).query()
    }, [query, cacheProcess, networkPolicy, args])

    const result = {
        data,
        error,
        loading,
    }

    return result
}

export default useQuery
