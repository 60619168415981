import * as React from 'react'
import { DataGridPro, GridLinkOperator } from '@mui/x-data-grid-pro'

import LinearProgress from 'common/LinearProgress'
import Alert from 'common/Alert'
import Snackbar from 'common/Snackbar'
import { removeReactFromColumns, addReactToColumns } from 'common/DataGrid/utils/column-utils'
import DataGridToolbar from 'common/DataGridToolbar'
import DataGridFooter from 'common/DataGridFooter'
import useLocalStorage from 'hooks/use-local-storage'

import { selectFilterType, selectFilter } from './utils/filter-utils.js'
import { selectSortType } from './utils/sort-utils.js'

const DataGrid = ({
    rows = [],
    error: networkError,
    columns: initialColumns,
    args,
    setArgs,
    loadMore,
    loadAll,
    setFilter,
    setSort,
    totalCount,
    loading,
    localStorageKey,
    ...props
}) => {
    const [error, setError] = React.useState(undefined)
    const [density, setDensity] = useLocalStorage('row-density', 'standard')
    const [columns, setColumns] = useLocalStorage(`${localStorageKey}-columns`, () => initialColumns, {
        serialize: removeReactFromColumns,
        deserialize: addReactToColumns(initialColumns),
    })
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
        `${localStorageKey}-column-visibility`,
        () => initialColumns.reduce((acc, { field }) => ({ [field]: true, ...acc }), {}),
    )

    const resetGrid = () => {
        setColumns(initialColumns)
        setColumnVisibilityModel(() => initialColumns.reduce((acc, { field }) => ({ [field]: true, ...acc }), {}))
    }

    React.useEffect(() => {
        setError(networkError)
    }, [networkError])

    React.useEffect(() => {
        if (rows.length > 50000) {
            setError('Error: Viewing over 50,000 rows is not supported. Please filter results.')
        }
    }, [rows.length])

    const changeFilters = (newFilters) => {
        const filter = newFilters.items.reduce((acc, item) => {
            const fieldName = item.columnField

            const filterValue = selectFilter(item)

            const type = selectFilterType(item.operatorValue)

            if (!filterValue && acc[fieldName]) {
                return acc
            }

            return {
                ...acc,
                [fieldName]: {
                    [item.operatorValue]: {
                        fieldName,
                        filter: filterValue,
                        type,
                    },
                },
            }
        }, {})

        setFilter({ filter, items: newFilters.items })
    }

    const changeSort = (newSorts) => {
        if (newSorts.length) {
            const item = newSorts[0]
            const key = item.field
            const value = selectSortType(item.sort)
            setSort({ key, value, items: newSorts })
        } else {
            setSort({ key: undefined, value: undefined, items: [] })
        }
    }

    const onRowsScrollEnd = () => {
        if (!rows.length || rows.length === totalCount) {
            console.log('total count issue')
            return
        }

        loadMore({ totalCount })
    }

    return (
        <>
            <DataGridPro
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: DataGridToolbar,
                    LoadingOverlay: LinearProgress,
                    Footer: DataGridFooter,
                }}
                componentsProps={{
                    toolbar: {
                        export: true,
                        filter: true,
                        resetGrid,
                    },
                    filterPanel: {
                        linkOperators: [GridLinkOperator.And],
                    },
                    footer: {
                        loadAll,
                        rows,
                        totalCount,
                    },
                }}
                sx={{ border: 0 }}
                loading={loading}
                // Force compact height for virtualization support: https://mui.com/components/data-grid/virtualization/
                getRowHeight={() => (rows.length > 50000 ? 36 : null)}
                // Pagination Props
                pagination
                paginationMode='server'
                onRowsScrollEnd={onRowsScrollEnd}
                scrollEndThreshold={100}
                // Filter Props
                filterMode='server'
                filterModel={{ items: args.filter.items }}
                onFilterModelChange={changeFilters}
                // Sorting Props
                sortingMode='server'
                sortModel={args.sort.items}
                onSortModelChange={changeSort}
                // Stateful Columns
                onColumnOrderChange={(e) => {
                    const target = e.targetIndex
                    const old = e.oldIndex
                    const newColumns = [...columns]
                    newColumns[target] = columns[old]
                    newColumns[old] = columns[target]
                    setColumns(newColumns)
                }}
                onColumnWidthChange={(e) => {
                    const newColumns = [...columns].map((col) => (
                        col.field === e.colDef.field
                            ? ({
                                ...col,
                                flex: undefined,
                                width: e.colDef.width,
                            }) : col
                    ))
                    setColumns(newColumns)
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                density={density}
                onStateChange={(params) => {
                    if (params.density.value !== density) {
                        setDensity(params.density.value)
                    }
                }}
                {...props}
            />

            <Snackbar
                open={Boolean(error)}
                onClose={() => setError(undefined)}
                autoHideDuration={5000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div>
                    <Alert severity='error'>
                        {error}
                    </Alert>
                </div>
            </Snackbar>
        </>
    )
}

export default DataGrid
