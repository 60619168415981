const selectGqlArgument = (ast, argName) => {
    const args = ast.definitions[0].selectionSet.selections[0]?.arguments

    const [selectedArg] = args?.filter((arg) => arg.name.value === argName)
    const value = selectedArg?.value.value

    return value
}

export default selectGqlArgument
